import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const ContactPageTemplate = ({
  title,
  locale,
  description,
  image,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <section className="subPage">
      <div className="image">
        <Img
          fluid={image.childImageSharp.fluid}
          alt={title}
          title={title}
          className="post-image"
        />
        <div className="titles">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="container">
        <div className="content c-form">
          <PostContent content={content} />
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12066.422649557875!2d29.2566935!3d40.8805274!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadc9fdfefb785%3A0xc1b5cca3cb09d734!2sVM%20Medical%20Park%20Pendik%20Hastanesi!5e0!3m2!1sen!2suk!4v1678642830856!5m2!1sen!2suk"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        aria-hidden="false"
        title={title}
      ></iframe>
    </section>
  );
};

ContactPageTemplate.propTypes = {
  locale: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContactPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.ContactPageData.edges[0];

  return (
    <Layout
      locale={locale}
      title={data.frontmatter.title}
      description={data.frontmatter.description}
    >
      <Helmet>
        <title>{data.frontmatter.title}</title>
        <meta name="description" content={`${data.frontmatter.description}`} />
      </Helmet>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        description={data.frontmatter.description}
        content={data.html}
        locale={locale}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const pageQuery = graphql`
  query contactPageQuery($locale: String) {
    ContactPageData: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "contact-page" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            description
            locale
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
